module.exports = {
  staticResources: {
    dropDowns: {
      type: {
        // textNone: 'Bitte wählen Sie',
        // errorMessage: 'Bitte treffen Sie eine Auswahl',
        content: ['Anzeigen', 'Datenbanksuche']
      }
    }
  }
};
