import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import CircularProgressbar from 'react-circular-progressbar';

class WillingnessToChangeCircle extends PureComponent {
  renderText() {
    return (
      <div className="label">
        Wechsel&shy;wahr&shy;schein&shy;lich&shy;keit:
        {
          // TODO move text to text.js
          // `${TEXT.resultPage.willingnessToChange}:`
        }
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.props.textAboveCircle ? this.renderText() : ''}
        <div className="progressbar">
          <CircularProgressbar
            percentage={this.props.willingnessToChange}
            text={`${Math.floor(this.props.willingnessToChange)}%`}
            strokeWidth={10}
          />
        </div>
        {!this.props.textAboveCircle ? this.renderText() : ''}
      </div>
    );
  }
}
WillingnessToChangeCircle.defaultProps = {
  textAboveCircle: true
};

WillingnessToChangeCircle.propTypes = {
  willingnessToChange: PropTypes.string.isRequired,
  textAboveCircle: PropTypes.bool
};

export default WillingnessToChangeCircle;
