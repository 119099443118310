const staticContent = [
  'spannende Aufgabe',
  'Verbesserung des Gehalts',
  'nächster Karriereschritt',
  'Arbeitsweg verkürzen',
  'mehr Flexibilität',
  'private Gründe',
  'sonstiges'
];
module.exports = staticContent;
