import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';

// // libraries
// import $ from 'jquery';

import ProjectMatchingForm from './ProjectMatchingForm';

import './ProjectMatching.css';
// import * as CONSTANTS from '../constants';
// import * as CONFIG from '../config';
// import * as UTILS from '../utils/utilFunctions';
// import TEXT_GENERAL from '../text';

// TAB: AA Matching
class ProjectMatchingTab extends PureComponent {
  render() {
    return (
      <div className="container-fluid">
        {this.props.selectedProject && (
          <Paper>
            <ProjectMatchingForm
              key={this.props.selectedProject.id}
              selectedProjectId={this.props.selectedProject.id}
              data={this.props.selectedProject.matching}
              isEditable={this.props.selectedProject.isEditable}
              handleSnackbarOpen={this.props.handleSnackbarOpen}
            />
          </Paper>
        )}
      </div>
    );
  }
}

ProjectMatchingTab.propTypes = {
  selectedProject: PropTypes.object.isRequired,
  handleSnackbarOpen: PropTypes.func.isRequired
};

export default ProjectMatchingTab;
