import * as CONSTANTS from '../../constants';

export const STATUS_TYPES = {
  available: {
    title: 'verfügbar',
    disabled: true
  },
  locked: {
    title: 'im Gespräch',
    validMonths: 3
  },
  placed: {
    title: 'vermittelt'
  },
  employed: {
    title: 'hat anderen Job',
    disabled: !CONSTANTS.isLienert
  }
};

export function mapCandidateStatus(statusType) {
  return (STATUS_TYPES[statusType] || {}).title || STATUS_TYPES.available.title;
}

export function getAvailableStatusTypes() {
  return Object.keys(STATUS_TYPES).filter(type => !STATUS_TYPES[type].disabled);
}
