const staticContent = Array.from({ length: (205000 - 50000) / 5000 }, (_, i) =>
  new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    maximumSignificantDigits: 6
  }).format(50000 + i * 5000)
);
staticContent.unshift('< 50.000 €');
staticContent.push('> 200.000 €');
module.exports = staticContent;
