import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';

import TEXT from './staticContent/text';

import GenericArrayForm from '../../generic_components/GenericArrayForm';

class IncomingCandidateTags extends PureComponent {
  generateFormRows() {
    const forms = [];
    if (this.props.selectedIncomingCandidate.tags)
      this.props.selectedIncomingCandidate.tags.forEach(tag => {
        forms.push({
          tag: {
            value: tag,
            label: TEXT.incomingCandidateSkills.tag,
            customClass: 'col-12 mb-2'
          }
        });
      });

    return forms;
  }

  handleConfirmButton = updatedData => {
    const tags = [];
    updatedData.forEach(tag => {
      tags.push(tag.tag);
    });
    const update = { tags };

    this.props.liftUpSelectedCandidateToIncomingCandidates(update);

    this.props.handleNext();
  };

  renderInputFields() {
    return (
      <div className="row">
        <div className="col-12 mt-3">
          <GenericArrayForm
            // key={this.props.selectedIncomingCandidate.id}
            formRows={this.generateFormRows()}
            handleSubmit={this.handleConfirmButton}
            handleCancel={this.props.handleBack}
            buttonSaveText={TEXT.incomingCandidates.buttonNext}
            title={TEXT.incomingCandidates.tags}
            buttonCancelText={TEXT.incomingCandidates.buttonBack}
            buttonSubmitText={TEXT.incomingCandidates.buttonNext}
            baseModel={{
              tag: {
                value: '',
                label: TEXT.incomingCandidateSkills.tag,
                customClass: 'col-12 mb-2'
              }
            }}
          />
        </div>
      </div>
    );
  }

  render() {
    return <Paper>{this.renderInputFields()}</Paper>;
  }
}

IncomingCandidateTags.defaultProps = {};
IncomingCandidateTags.propTypes = {
  selectedIncomingCandidate: PropTypes.object.isRequired,
  handleNext: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  liftUpSelectedCandidateToIncomingCandidates: PropTypes.func.isRequired
};
export default IncomingCandidateTags;
